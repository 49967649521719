import { OsQueryItemOption } from './../../../components/os-table-query/os-table-query';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { Paging } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { getStatusClass, getStatusI18Key, messageError, showWarningConfirm, translation } from '@/utils';
import { ResourceStatusEnum } from '@/resource/enum';
import { supplierClassifyService } from '@/api';
import { Message } from 'element-ui';
import { SupplierClassifyResource } from '@/resource/model';
import AddSupplierClassify from './add-supplier-classify/add-supplier-classify.vue';

@Component({
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, AddSupplierClassify }
})
export default class SupplierClassify extends Vue {
  public $table = this.$refs.classifyTable as OsTable;

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<SupplierClassifyResource> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { prop: 'createTime', order: 'descending' }
  };

  /**
   * 查询表单数据对象
   */
  public queryForm: Partial<{
    keywords: string;
    status: ResourceStatusEnum | null;
  }> = {
    keywords: '',
    status: null
  };

  /**
   * 表格查询区域配置
   */
  public queryFormOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: [
        {
          label: this.$t('common.startUsing'),
          value: 1
        },
        {
          label: this.$t('common.disabled'),
          value: 2
        }
      ]
    }
  ];

  /**
   * 表格工具栏配置
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      operationType: 'add',
      slot: 'start',
      label: 'button.add',
      type: 'primary',
      icon: 'el-icon-plus',
      permissionCode: 'supplier:category:save',
      handleClick: (): void => {
        this.openAddDialog();
      }
    },
    {
      operationType: 'delete',
      slot: 'start',
      label: 'button.delete',
      type: 'danger',
      icon: 'el-icon-delete',
      plain: true,
      disabled: true,
      permissionCode: 'supplier:category:batchDelete',
      handleClick: (): void => {
        this.batchDelete();
      }
    },
    {
      operationType: 'using',
      slot: 'end',
      label: 'button.using',
      type: 'primary',
      icon: 'el-icon-open',
      disabled: true,
      permissionCode: 'supplier:category:editStatus',
      handleClick: (): void => {
        this.batchEnable();
      }
    },
    {
      operationType: 'disabled',
      slot: 'end',
      label: 'button.disabled',
      type: 'danger',
      plain: true,
      icon: 'el-icon-turn-off',
      disabled: true,
      permissionCode: 'supplier:category:editStatus',
      handleClick: (): void => {
        this.batchDisable();
      }
    }
  ];

  /**
   * 表格字段配置
   */
  public tableColumnOptions: Array<OsTableColumn<SupplierClassifyResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'categoryCode',
      label: 'classify.code',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'categoryName',
      label: 'classify.name',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOption: RowOperation<SupplierClassifyResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'supplier:category:edit',
        handleClick: (row: SupplierClassifyResource): void => {
          this.openAddDialog(row);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'supplier:category:delete',
        handleClick: (row: SupplierClassifyResource): void => {
          this.delete(row);
        }
      }
    ]
  };

  /**
   * 分页数据对象
   */
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  /**
   * 总记录数
   */
  public totalData = 0;

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<SupplierClassifyResource> = [];

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  /**
   * vue实例创建钩子
   */
  public mounted(): void {
    this.$table = this.$refs.classifyTable as OsTable;
    this.loadData();
  }

  /**
   * 查询按钮点击事件
   */
  public handleQueryClick(): void {
    this.reloadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<SupplierClassifyResource>): void {
    this.selectedRows = selectedData;
  }

  /**
   * 表格行选中数据监听
   */
  @Watch('selectedRows')
  public handleSelectionChanged(value: Array<SupplierClassifyResource>): void {
    this.tableToolbarOptions.forEach(option => {
      if (option.operationType !== 'add') {
        option.disabled = value.length === 0;
      }
    });
  }

  /**
   * 重新加载数据
   */
  public reloadData(): void {
    this.paging.currentPage = 1;
    this.$table.clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  /**
   * 打开新增/编辑窗口
   * @param data 分类数据
   */
  public openAddDialog(data: SupplierClassifyResource | null = null): void {
    const $dialog = this.$refs.addDialog as AddSupplierClassify;

    $dialog.openDialog(data);
  }

  /**
   * 编辑回调，更新表格数据
   * @param data 回调数据
   */
  public handleEditSuccess(data: SupplierClassifyResource): void {
    const findItem = this.tableOption.data.find(row => row.id === data.id);

    Object.assign(findItem, data);
  }

  /**
   * 查询数据
   */
  private loadData(): void {
    this.$table.openLoading();
    supplierClassifyService
      .getList(this.queryForm as SupplierClassifyResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.$table.closeLoading();
      });
  }

  /**
   * 批量启用
   */
  private batchEnable(): void {
    this.batchUpdateStatus(ResourceStatusEnum.using);
  }

  /**
   * 批量禁用
   */
  private batchDisable(): void {
    this.batchUpdateStatus(ResourceStatusEnum.disabled);
  }

  /**
   * 批量更新状态
   */
  private batchUpdateStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(row => row.id);
    supplierClassifyService
      .batchUpdateStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 删除分类
   * @param row 分类对象
   */
  private delete(row: SupplierClassifyResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await supplierClassifyService.delete(row.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  /**
   * 批量删除分类
   */
  private batchDelete(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(row => row.id);
          await supplierClassifyService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }
}
