import { SupplierClassifyResource } from './../../../../resource/model/supplier-management/supplier-classify.d';
import { Component, Vue } from 'vue-property-decorator';
import { messageError, translation } from '@/utils';
import { supplierClassifyService } from '@/api';
import { Message, Form } from 'element-ui';

@Component({
  components: {}
})
export default class AddSupplierClassify extends Vue {
  /**
   * 提交loading 状态
   */
  public submitLoading = false;

  /**
   * dialog显示状态
   */
  public dialogVisible: boolean | null = false;

  /**
   * dialog 标题
   */
  public title = '';

  /**
   * 传入分类对象
   */
  public classify: SupplierClassifyResource | null = null;

  /**
   * 表单数据对象
   */
  public formData: {
    categoryName: string;
    id: number | null;
  } = {
    /**
     * 分类名称
     */
    categoryName: '',
    /**
     * id
     */
    id: null
  };

  /**
   * 表单校验规则
   */
  public formRules = {
    categoryName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('classify.inputName')));
            return;
          }

          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          supplierClassifyService
            .checkRepeatName(value, this.formData.id!)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('requestError.classifyNameRepeat'));
                return;
              }

              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ]
  };

  /**
   * 操作类型,是否新增操作
   */
  private isCreate = true;

  /**
   * 提交数据
   */
  public handleSubmit(): void {
    const $form = this.$refs.form as Form;

    $form.validate(async valid => {
      if (!valid) {
        return;
      }

      this.startLoading();

      try {
        if (this.isCreate) {
          await supplierClassifyService.post({
            ...this.formData
          } as any);

          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
        } else {
          await supplierClassifyService.put({
            ...this.formData
          } as any);

          Message.success(translation('operationRes.editSuccess'));
          this.$emit('edit-success', this.formData);
        }

        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.endLoading();
      }
    });
  }

  /**
   * dialog打开事件
   */
  public handleOpen(): void {
    if (!this.classify) {
      this.isCreate = true;
      this.title = 'classify.addClassify';
      return;
    }

    this.isCreate = false;
    this.title = 'classify.editClassify';

    this.formData.id = this.classify.id;
    this.formData.categoryName = this.classify.categoryName;
  }

  /**
   * dialog关闭事件
   */
  public handleClosed(): void {
    this.resetForm();
    (this.$refs.form as Form).resetFields();
  }

  /**
   * 打开dialog
   * @param data 传入分类数据
   */
  public openDialog(data: SupplierClassifyResource | null = null): void {
    this.classify = data;

    this.setDialogVisible(true);
  }

  public closeDialog(): void {
    this.setDialogVisible(false);
  }

  public startLoading(): void {
    this.setSubmitLoading(true);
  }

  public endLoading(): void {
    this.setSubmitLoading(false);
  }

  public setDialogVisible(value: boolean): void {
    this.dialogVisible = value;
  }

  public setSubmitLoading(value: boolean): void {
    this.submitLoading = value;
  }

  /**
   * 重置表单数据
   */
  private resetForm(): void {
    this.formData = {
      categoryName: '',
      id: null
    };
  }
}
